@import "reece-styles.css";
@import "kobus.css";

* {
  font-family: 'Work Sans', sans-serif;
  /* border: 0.05px solid rgb(255, 170, 0); */
}

p {
  color: #404040;
  font-weight: 200px !important;
}



/* reusable elements */

.orange-icon {
  color: #f19601;
}

.green-icon {
  color: #00dd94;
}

.brown-icon {
  color: #822424;
}

.blue-icon {
  color: #00069a;
}



.full-screen-container-2 {

  /* height: 100vh; */
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.serious-font {
  font-family: 'DM Serif Display', serif;
  /* font-family: 'Work Sans', sans-serif; */


}

.lcn-button-orange {
  background-color: #f19601;
  border: 3px solid #f19601;
  border-radius: 30px;
  color: #832626;
  font-family: 'Work Sans', sans-serif;
  white-space: nowrap;
  cursor: pointer;
}

.lcn-button-orange:hover {
  background-color: #fcb900;
  transition: 0.3s;
}


.lcn-button-brown {
  background-color: #832626;
  border: none;
  border-radius: 30px;
  padding: 14px 24px;
  color: white;
  /* margin: 0px 8px; */
  font-family: 'Work Sans', sans-serif;
  justify-content: center;
  align-items: center;
}

.lcn-button-brown:hover {
  background-color: #f19601;
  transition: 0.3s;
  color: #822424;
}

.lcn-button-brown-no-padding {
  background-color: #832626;
  border: none;
  border-radius: 30px;
  color: white;
  font-family: 'Work Sans', sans-serif;
  justify-content: center;
  align-items: center;
}

.lcn-button-grey {
  background-color: #404040;
  border: none;
  border-radius: 30px;
  padding: 14px 24px;
  color: white;
  margin: 0px 8px;
  font-family: 'Work Sans', sans-serif;
  justify-content: center;
  align-items: center;
}


.lcn-button-orange-outline {
  background-color: white;
  border: 3px solid #f19601;
  border-radius: 30px;
  color: #832626;
  margin: 0px 8px;
  white-space: nowrap;
}

.lcn-button-orange-outline:hover {
  background-color: #fcb900;
  transition: 0.3s;
}

.ll-no-margin{
  margin:0px;
}
.error-message {
  color: red;
}

/* All Screens Navbar */

.navbar-custom {
  background-color: white !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 100;
}

.navbar-custom.active {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}

.home-nav-link {
  font-weight: 500;
}

/* .dropdown:hover .dropdown-menu {
  display: block !important;
  } */

#basic-nav-dropdown {
  color: #383838;

}

.dropdown-toggle::after {
  display: none !important;
}

img.lcn-nav-logo {
  width: 11rem;
}

.navbar-brand{
  margin-left: 3rem;
  margin-top: 1rem;
}

#right-nav {
  display: flex;
  /* flex-direction: column !important; */
  align-items: center !important;
  margin-left: 0px !important
}

.nav-link {
  padding-top: 13px !important;
}

.login-button {
  color: #f19601 !important;
  font-weight: 500;
}

/* HomeScreen */

.section {
  padding: 128px 0px;
}

#homepage-container {
  padding-left: 0px;
  padding-right: 0px;

}

.homepage-hero {
  padding: 6rem 0 5rem 0;

}

#hero-header {
  font-size: 3em;
  font-weight: 700;
  margin: 16px 0 32px 0;
  color: #404040;
}

#hero-slogan {
  margin-bottom: 32px;

}

.homepage-health-assessment {
  color: #f19601;
  font-weight: 400;
  cursor: pointer;
}

.homepage-card {
  min-height: 160px;
  padding-top: 36px;
  border: none !important;
  transition: all .2s ease-in-out;
  cursor: pointer;

}

.homepage-card:hover {
  transform: scale(1.05);
  box-shadow:  -11px -11px 37px #dfdfd3,
  11px 11px 37px #ffffff;
}

.homepage-howto-number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-family: 'DM Serif Display', serif;
  /* background-color: #f19601; */
  color: #822424;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  /* border: 3px solid #822424; */
}

.homepage-howto-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #f19601;
  font-family: 'DM Serif Display', serif;
}

.homepage-howto-text-wrapper {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.homepage-howto-number-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fa-regular {
  height: 30px;
}

#homepage-cream-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafaed;
  padding: 16px 0px 36px 0px;
  width: 100%;
}

.homepage-section {
  padding-top: 64px !important;
  padding-left: 16% !important;
  padding-right: 16% !important;

}

.homepage-section-cream {
  /* padding-top: 64px !important; */
  margin: 64px 0 0 0 !important;
  padding: 128px 0 128px 0;
  background-color: #fafaed;
  width: 100%;

}

/* .homepage-credential {
  padding: 16px;
 border: 3px solid #404040;
  box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.25);

  border-radius: 4px;
  background-color: #fafaed;
} */

.homepage-how-to {
  background-color: white;
  /* border: 3px solid #f19601; */
  padding: 32px 16px 32px 16px;
  box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #404040;
  font-family: 'DM Serif Display', serif;


}

.how-to-heading {
  color: #822424;
  margin-bottom: 32px;
}

#how-to-image {
  width: 300px;
}

/* https://mdbootstrap.com/docs/react/utilities/spacing/ */

/* Sidenav */

h3.side-dash-name-text{
  color: #383838;
}

/* orange collapsible nav list on mobile */

@media only screen and (max-width: 992px) {

  #basic-navbar-nav {
    padding: 0px;
    width: 100% !important;
    visibility: visible;
    background-color: #f1950100;
    margin-top: 8px;
    border-radius: 4px;
  }

  div#dash-sidenav-column{
    padding: 5px !important;
  }

  img.lcn-sidenav-logo{
    width: 150px !important;
  }

  h3.dash-sidepanel-link-text{
    font-size: 1rem !important;
  }

  div.dashboard-container.row{
    display: flex;
    position:relative;
    flex-direction: column !important;
    width:100%;
  }

  /* div .sidenav-container-inner{
    flex-direction: row !important;
  } */
  .sidenav-container-outer{
    height:20vh !important;
  }
  .sidenav-container-inner{
    height: 20vh !important;
    display: flex;
    flex-direction: row-reverse !important;
  }

   /* SideNav */
   .sidenav-container-inner{
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .sidenav-container-inner::-webkit-scrollbar {
    display: none;
  }

  .dash-sidepanel-link.row{
    width:auto;
  }

  /* hamburger menu */

  .navbar-toggler {
    visibility: visible;
    background-color: #f19601 !important;
    /* margin-top: auto !important;
    position: fixed !important;
    overflow: auto !important; */
  }

  .dashboard-content-container {
    padding-top: 50px;

  }

}

.sidenav-text-wrapper {
  display: flex;
  align-items: center;
}

.sidenav-text {
  font-size: 20px;
  font-weight: 500;
}

.sidenav-button {
  border-radius: 8px;
}

.sidenav-button:hover {
  background-color: #832626;
  transition: 0.2s;
  cursor: pointer;
}

.sidenav-button-text {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  font-weight: 800;
}

.sidenav-container {
  padding: 0px !important;
}

.me-auto {
  flex-direction: column !important;
  min-width: 100% !important
}

.sidenav-link {
  display: flex !important;

  min-width: 100%;
  border-radius: 8px;
}

.sidenav-link:hover {
  background-color: #832626;
  ;
}

#sidenav-signup-button-text {
  font-family: 'DM Serif Display', serif;
  color: white;

}

/* General dashboard classes */


.dashboard-header {
  font-family: 'DM Serif Display', serif;
  color: #822424;

}

.dashboard-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content-divider {
  height: 50px;
}



/* Documents */

.document-date {
  font-family: 'Work Sans', sans-serif;
  color: #404040;
}

.document-button {
  width: 50% !important;
  margin-left: auto !important;

}

/* Find a Lawyer */

.find-lawyer-hero {
  padding-top: 64px;
}

.find-a-lawyer-hero-heading {
  font-family: 'DM Serif Display', serif;
  color: #822424;

}

.find-a-lawyer-image {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  color: #f19601;
}

.find-a-lawyer-guidance-card {
  height: 100% !important;
  background-color: white;
  border: 2px solid #822424;
  border-radius: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 30%);
  font-size: 14px;
}

.find-a-lawyer-guidance-card-number-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #822424;
  border-radius: 16px;
}

.find-a-lawyer-guidance-card-number {
  font-family: 'DM Serif Display', serif;
  color: #fafaed;
  font-size: 22px;

}


.form-step {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  padding: 32px !important;
}

.form-step-heading {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.find-a-lawyer-form-container {
  /* Commented out some important top padding of 64px here */
  padding-bottom: 128px !important;
  display: flex;
  flex-direction: column;
  align-items:center;
}

.form-step-next-button-container {
  display: flex !important;
  justify-content: flex-end !important;
}

/* UserDash*/

#user-dash-hero {
  border-radius: 8px;
  color: #404040;
  box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.75);

}

.user-profile-pic {
  background-color: #822424;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.user-profile-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 16px;
}

#user-dash-hero-right {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

/* #user-dash-hero > * {
  color: red;
} */


#user-dash-hero-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.user-dash-hero-left-heading {
  font-family: 'DM Serif Display', serif;
  /* color:#f19601; */

}

#user-dash-hero-right-heading {
  font-family: 'DM Serif Display', serif;

}

#user-dash-hero-right {
  background-color: white;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.dash-score {
  font-weight: 800;

}

.dash-all-scores-row {
  height: 150px;
}

.dash-score-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#total-score {
  text-align: center;
  font-weight: 600;
}

#score-container {
  /* border: 3px solid #822424; */
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #f19601; */
  color: #404040;
  border: 1px solid #822424;
}

.dashboard-product-selector {
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f19601;
  background-color: #822424;
}

.dashboard-product-selector-large {
  border: 1px solid black;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  color: #822424;
}

#dashboard-product-selector-heading {
  font-family: 'DM Serif Display', serif;
}

.dashboard-product-selector-large-heading {
  font-family: 'DM Serif Display', serif;

}

div.user-profile-pic{
  width: 12rem;
  height: 12rem;
}

div.user-profile-pic-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .user-details-form .form-label{
  font-family: 'DM Serif Display', serif;
} */

.user-details-form .form-control{
  border-radius: 2rem;
  padding:1rem;
}

div.sub-parent{
  border:dashed 2px;
  border-radius: 2rem;
  padding:1rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

div.sub-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}



/* Login */

#login-register-hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Group_376.png"); */
  height: 800px;
  position: relative;
  /* height: 100%; */
}

.login-register-hero {
  display: flex;
  justify-content: center;
  background-color: #fafaed;
  border-radius: 8px;
  position: absolute;
  width: 90%;
}

.login-register-image {
  /* min-width: 100%;
  min-height: 100%; */
  /* width: auto;
  height: auto; */
  max-width: 100%;
  height: auto;
  /* object-fit: contain; */
}

/* .login-register-container {
  min-height: 100%;
} */



/* .login-form-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
} */

/* .login-form-container {
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.75);


}
 */


.login-register-hero-button-left-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.login-register-hero-button-right-container {
  display: flex;
  flex-direction: row;
}

.login-hero-button {
  width: 150px;
  height: 50px;
}

/* Footer */

/* #footer-container {
  background-color: #404040;
} */

#footer-logo {
  width: 80px;
}

#footer-present {
  width: 80px;
}

.footer-small-text>* {
  font-size: 12px;
}

.footer-link-header {
  font-weight: bold;
}

.footer-link {
  color: #822424;
  cursor: pointer;
}

#footer-icon-row {
  display: flex;
}

/* bots */

.MyLandbot {
  min-height: 600px;
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
}

/* Incubator profile */

.generate-reports-container {
  display: flex;
  justify-content: center;
}

#generate-reports {
  width: 50%;
}

/* avatar */

.avatar-image {
  width: 50px;
}

.avatar-column {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
  /* max-height:100px; */
}

.avatar-wrapper {
  background-color: #822424;
  border-radius: 100%;
}

#dropdown-basic {
  /* padding: 0px !important; */
  background-color: #f1950100 !important;
  border: 1px solid #f1950100 !important;
}

#dropdown-basic:focus {
  box-shadow: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #383838 !important;
  text-decoration: none;
  background-color: #ffffff !important;
}

/* dash css */

main {
  /* box-sizing: border-box; */
  min-height: 100vh;
  width: 100%;
}


.dashboard-scrollable-container {
  /* display: flex; */
  /* overflow: auto; */
  margin-left: auto !important;
  /* align-self: flex-end !important; */
  align-items: stretch;
  width: 100%;
  margin-top: 10%;
}

/* .dashboard-content-container.row.faq-container{
  margin-top:17%;
} */

.dashboard-header-container {
  display:none;
  background-color: #f1950100;
  /* width: 100%; */
  z-index: 100;
  height: 90px;
  /* position: fixed !important; */
  /* top: 0 !important; */
}

/* tooltips */

.tooltip-test:hover::before {
  content: attr(data-tooltip)
}

/* Product intro */

.product-intro-columns-wrapper {
  /* height: 500px; */
  color: #404040;
}

.product-intro-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bot-category {
  max-width: fit-content;
}

.product-intro-image {
  width: 250px !important;
  border-radius: 100%;
}

/* ForIncubatorsScreen Component Styles */

div.front-page-container{
  max-width: 100%;
  padding:0;
}

.utility-divider-lines {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3px;
  background-color: #832626;
}

.divider-icon {
  color: #822424;
}

.divider-parent.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-right: auto;
  margin-left: auto;
}

/* .page-container {
  margin-top: 100px;
} */

.util-icon-parent.col {
  max-width: 10%;
  align-items: center;
  justify-content: center;
}

.hero-header-center {
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 2.5em;
  font-weight: 700;
  margin: 16px 0 32px 0;
  color: #404040;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0.455) 75%, rgba(255, 255, 255, 0) 100%);
}

.hero-header-blurb{
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0.455) 75%, rgba(255, 255, 255, 0) 100%);
}

.hero-container.row {
  display: relative;
  align-items: center;
  justify-content: center;
}

i.divider-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-button-parent.row {
  width: 60%;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

#for-inc-split-ball {
  position: absolute;
  width: 60px;
  height: 60px;
  right: 25%;
  bottom: 55%;
  z-index: -1000;
  filter: blur(0px);
}

#for-inc-ring {
  width: 60px;
  position: absolute;
  top: 17%;
  z-index: -100;
}

#for-inc-skwigle {
  width: 60px;
  z-index: -1;
  position: absolute;
  top: 40%;
  left: 25%;
  height: 60px;
}

.lcn-swing {
  animation: lcn-swing-animation linear 20s;
  transform-origin: 50% 0%;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 0%;
}

.lcn-slide {
  animation: lcn-slide-animation linear 20s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: lcn-slide-animation linear 20s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
}

.lcn-bounce {
  animation: animationFrames linear 20s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames linear 20s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
}

.lcn-section-spacing {
  padding: 2.5rem 75px;
}

div.lcn-fullwidth-section{
  max-width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

div.lcn-page-width-section{
  max-width: 80%;
}

.ll-form-back-btn{
  display: flex;
  flex-direction: row;
  align-items:left;
}

/* BootstrapClass */

div.ll-section-1{
  max-width:70%;
}
div.ll-section-2{
  max-width: 30%;
}

.ll-content-image {
  max-height:20rem;
}
@media only screen and (max-width: 1400px) {
  div.ll-section-1{
    max-width:70%;
  }
  div.ll-section-2{
    max-width: 30%;
  }
  div.lcn-page-width-section{
    max-width: 90%;
  }
}
@media only screen and (max-width: 1300px) {
  div.lcn-page-width-section{
    display: flex;
    flex-direction: column;
  }
  div.ll-section-1{
    max-width:100%;
  }
  div.ll-section-2{
    max-width: 100%;
  }
  div.ll-flex-col-reverse{
    display: flex;
    flex-direction:column-reverse ;
  }

}
@media only screen and (max-width: 992px) {
  div.lcn-page-width-section{
    max-width: 95%;
  }
  .hero-header-center{
    font-size: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  div.lcn-page-width-section{
    max-width: 100%;
  }
  .ll-content-image {
    max-height:15rem;
  }
  .ll-prod-title{
    text-align: center;
  }
}
@media only screen and (max-width: 576px) {
  div.lcn-page-width-section{
    max-width: 100%;
  }
  .ll-content-image {
    max-height:10rem;
  }
  .hero-header-center{
    font-size:1.7rem;
  }
}


/* ll content sections for the front end */
.ll-frontend-content-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ll-front-content-block-text-parent {
  text-align: start;
}

.ll-pre-title {
  font-family: 'DM Serif Display', serif;
  color: #f19601;
  font-size: 1.5em;
}

.ll-subheading {
  font-weight: 700;
  color: #404040;
}

a.ll-cta-icon-parent {
  display: flex;
  text-decoration: none;
  color: #f19601;
}

.ll-cta-icon-parent p {
  color: #f19601;
}

i.ll-cta-icon {
  color: #f19601;
  font-size: 20px;
  margin: 0 10px;
  padding-top: 2px;
}


.ll-content-image-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}



/* ll mini cards section */
.ll-mini-card {
  border-radius: 20px;
  padding: 16px 6px;
  align-items: center;
}

.ll-mini-card:hover {
  box-shadow: 0px 0px 10px 5px rgb(56 56 56 / 5%);
}

.ll-mini-card-inner {
  text-align: start;
  border-left: #f19601 solid 2px;
  padding-left: 10px;
}

.ll-mini-card-inner p,
.ll-mini-card-inner i {
  vertical-align: middle;
}

.ll-mini-card-title {
  font-size: 1em;
  font-family: 'DM Serif Display', Serif;
  margin-bottom: 0;
}

/* Form and Tagline Section */
.ll-dark-section {
  overflow: visible;
  left: 0;
  background-color: #832626;
  color: #fafaed;
}

.ll-front-content-block-text-parent-dark>p,
.ll-front-content-block-text-parent-dark>h3 {
  text-align: start;
  color: #fafaed;
}

/* tagline Styles */
.ll-title-light {
  font-size: 3em;
  font-family: 'DM Serif Display', Serif;
  color: #fafaed;
}

/*  Form Styling - Dark */

.form-step-heading-dark {
  color: #822424;
}

/* Sidenav alt styles */
div .sidenav-container-inner {
  height: 95vh;
  width: 90%;
  background-color: #fafaed;
  border-radius: 20px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y:auto;
  overflow-x:hidden;
}

#dash-sidenav-column {
  background-color: #fafaed;
}

div.sidenav-container-outer {
  height: 100vh;
  background-color: #ffffff00;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: fixed;
  vertical-align: middle;
}

div.sidenav-logo-container {
  margin: 0;
  justify-content: center;
}

.dash-sidepanel-link.row {
  max-width: 20rem;
  min-width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.dash-sidepanel-link.nav-link {
  max-width: 14rem;
  min-width: 14rem;
  display: flex;
  margin: 0px;
  padding: 0px;
}

.dash-sidepanel-link-icon-parent.col {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-sidepanel-section-bottom.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

h3.dash-sidepanel-link-text {
  font-size: 1.1em;
  margin-bottom: 5px;
}

div.dash-sidepanel-link-text-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  color: #383838;
}

img.lcn-sidenav-logo {
  width: 200px;
}

.divider-parent-mini.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin:1rem 0rem;
}

/* Dashhovericons */

/* Lottie-Icon */
.lottie-icon {
  width: 3.125rem;
  height: 3.125rem;
}

/* Alt Dash user welcome */

.side-dashboard-user-welcome.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

div.avatar-wrapper-side-panel {
  border-radius: 200px;
  background-color: #832626;
  width: auto;
}

.side-dash-welcome-text {
  font-size: 1em;
  color: #f19601;
}

.side-dash-name-text {
  font-size: 1.3em;
  ;
}

/* Dashboard Background Styles */
div.dashboard-container.row {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  background-color: #fafaed;
  background-image: url("https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-Legal-Back-banner.svg");
  background-repeat: repeat-x;
}


.dashboard-content-container.row {
  min-height: 100vh;
  margin-top: 10%;
  align-content: flex-start;
}

.ll-feat-font {
  font-family: 'DM Serif Display', serif;
}

/* Dashboard metric cards */
.ll-featured-metric-card.row{
  flex-wrap: nowrap;
  flex-direction: column;
  border-radius: 20px;
  padding:1.5rem;
  margin-bottom: 1rem;
}


.ll-feature-card-subtext{
  font-size: 1.2rem;
  color: #ababab;
  font-weight: 400;
}

/* div.ll-featured-metric-card-bottom-info{
  display: flex;
  flex-direction: row;
} */

.ll-featured-metric-feature{
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.ll-featured-card-metric-number{
  font-size: 3.5rem;
}

.ll-featured-card-metric-percent{
  font-size: 2rem;
  margin-left: 0.5rem;
  align-items: baseline;
}

.ll-featured-metric-card-last-meta{
  display: flex;
  flex-direction:column;
  text-align: right;
  height:100%;
  margin-top: auto;
  width:50%;
}

  .ll-featured-metric-card-metric{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 50%;
  }

  .ll-card-interact{
    border:#383838 2px solid;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition:all 0.4s ease-in-out;
  }
.ll-card-interact:hover{
  border:#38383800 2px solid;
  scale: 1.02;
  box-shadow: 0px 0px 16px 0px rgb(0 0 0 / 25%);
}

div.ll-featured-metric-card-top-info.row,
div.ll-metric-card-date.col{
  margin-right: 0;
  padding-right: 0;
}
/* ll General Dash metric */
.ll-metric-card.row{
  padding:1rem;
  border-radius: 1.2rem;
  margin-top:0px;
  margin-left:0px;
  margin-right: 0px;
  margin-bottom:1rem;
}



.ll-metric-card-date.col{
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-end;
}

/* Metric Card standard */

.ll-metric-percent {
    font-size: 2rem;
    margin-left: 0.5rem;
}

.ll-metric-card-text{
  font-size: 1em;
  font-weight: 400;
  color: #ababab;
}

.ll-metric-general{
  font-size: 1.8em;
}

.ll-metric-percentage-general{
  font-size: 1.5em;
  margin-left: 0.5rem;
}

.ll-align-items-end{
  align-items:flex-end;
}

/* Company Details section */
.ll-dash-company-details{
  border-left: #832626 solid 2px;
  margin-left: 1rem;
}

.ll-grey-colour{
  color: #ababab;
}

.ll-font-wt-light{
  font-weight: 400;
}

/* Quick Link section */
.ll-dash-quick-links-parent{
  flex-direction: column;
  flex-wrap: wrap;

}

.ll-metric-card-container.ll-quick-link-card.col{
  padding-right: 0px;
}

/* Button Icon Size Setting */
div.Archive-docs-sn > svg,
div.scales-just-sn> svg,
div.lawyer-sn> svg,
div.legalbyte-sn> svg,
div.key-sn>svg,
div.layers-dash-sn>svg,
div.legalbyte-ql> svg,
div.lawyer-ql> svg,
div.legalflow-ql> svg,
div.document-ql> svg,
div.reg-lot>svg{
  width: 3.125rem !important;
}

/* Card Icon Size Setting */
div.info-rc>svg,
div.info-io>svg,
div.info-eo>svg{
  width:3.125rem !important;
}

.start-your-journey-parent.row{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* align-content: center; */
  justify-content: space-around;
}
h4.start-you-journey-text{
  text-align: center;
}
div.journey-start-dash{
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.journey-start-dash>svg{
  width:10rem !important;

}

/* Legal Product */

.legal-product-container {
  border: 2px solid #832626;
  border-radius: 30px;
  background-color:#fafaed;
  position: relative;
  /* cursor: pointer; */
}

.legal-product-content {
  position:relative;
  inset: 0;
  background-color: #832626;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  color: #fafaed;
  padding: 20px;
  overflow: auto;
  border-radius: 20px;
  transition: 200ms ease-in-out;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* border: 2px solid #832626; */
}

.legal-product-content::-webkit-scrollbar {
  display: none;
}
.legal-product-content:hover {
  transform: scale(1.02);
}

.legal-product-go {
  display: flex;
  justify-content: flex-end;
}



.legal-product-image {
  max-width: 100px !important;
  border-radius: 100%;
  border: 3px solid #832626;


}

.legal-product-image-container {
  width: 100px !important;
  height: 100px !important;
}

.legal-product-title {
  font-family: 'DM Serif Display', serif;
  color: #404040;
}

.legal-product-title-2 {
  font-family: 'DM Serif Display', serif;
  color: #fafaed;
}

/* Tags Updated */
.lcn-category-brown{
  display: flex;
  font-size: 0.9em;
  background-color: #832626;
  border-radius: 30px;
  color: white;
  margin: 0px 8px;
  font-family: 'Work Sans', sans-serif;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

div.MyLandbot{
  padding:0;
  margin-top:2rem;
}
.ll-faq-parent{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

h5.legal-product-title{
  margin:0;
}

div .ll-faq-parent.col{
display: flex;
flex-direction: column;
flex-wrap: wrap;
}

/* Animation */

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated-1s {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

div.search-results-container{
  margin-top:10%;
}

div.dash-search{
  position:absolute;
  top:5%;
  right:10%;
  width:6rem;
  transition: 1s;
}

div.dash-search:hover{
  width:50%;
  transition: 1s;
}

div.dash-search>form{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  background:#fafaed;
  padding:0.5rem;
  border-radius: 6rem;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}

div.dash-search>form>input{
  border-radius: 2rem;
  width:0;
  padding:0.8rem 0;
  transition: 1s;
}

div.search-icon>svg{
  width: 2rem !important;
}

.dash-search:hover input{
  width:100%;
  padding:0.8rem;
  transition: 0.9s;
}
/* Kobus Merge Styles */


.login-form-container-header{
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.login-form-container-register{
  color: #832626;
  cursor: pointer;
}

.login-form-background-right{
  background-color: #f19601;
  z-index: 0;
}

.login-form-background-left{
  z-index: 0;
  background-color: #fafaed;
}





div.reg-login-special-container{
  display: flex;
  flex-direction:column ;
  justify-content:stretch;
  align-items: stretch;
  align-content: stretch;
  height:90vh;
  max-width: 100vw !important;
}

/* Reece Merge Styles */
/* .ll-thankyou-nav-card{
  width:100%;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
} */

.scales-ty,
.layer-ty,
.journey-ty{
  width:10rem;
}

.title-ty-parent.row{
  text-align: center;
}
.title-ty-parent.row>h1{
  color: #822424;
}
div.ll-ty-go-now-btn{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.ll-frontend-search{
  width: 12rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: right;
}
.ll-frontend-search>form{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ll-frontend-search>form>input{
  border-radius: 2rem;
  width:0;
  padding:0.3rem 0;
  border-color: #82242400;
  transition: all 1s;
}

.ll-frontend-search:hover input{
  width:10rem;
  padding:0.3rem 1rem;
  border-color: #822424;
  transition: all 1s;
}

.ll-frontend-nav{
  margin-right: calc(21%-10rem);
}

.ll-frontend-nav-container{
  display: flex;
  flex-direction: row;
  margin-right: -10rem;
}

.ll-document-card{
  align-items: center;
}

.ll-document-card-title{
  font-family: 'Work Sans', sans-serif;
}

.ll-doc-title-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.ll-doc-date-parent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100% !important;
}

.ll-doc-go-parent{
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-content: center;
}

div.faq-content-parent{
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
  width:90%;
}
.search-results-container{
  margin-top:30%;
}

.ll-search-results-title{
margin-top:20%;
}

.me-auto.navbar-nav{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Mobile Dash Nav Styles */
div.ll-dash-mobile-nav{
  display:none;
  position:fixed;
  /* height:5.5rem; */
  bottom:11%;
  right: 0;
  width:100%;
  transition:all 1s;
}

/* div.ll-dash-mobile-height{
  height:1.2rem;
}

div.ll-dash-mobile-height-expand{
  height:6.2rem !important;
} */

div.Archive-docs-sn > svg,
div.scales-just-sn> svg,
div.lawyer-sn> svg,
div.legalbyte-sn> svg,
div.key-sn>svg,
div.layers-dash-sn>svg,
div.legalbyte-ql> svg,
div.lawyer-ql> svg,
div.legalflow-ql> svg,
div.document-ql> svg{
z-index:1 !important;
position: relative;
transform: translate3d(0,0,-1);
}

.ll-dash-mobile-nav{
  position: relative;
  z-index: 1000;
}

.ll-dash-mobile-nav-items{
  background-color:#fafaed;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
  margin-bottom: 2rem;
  border:#822424 solid 2px;
}

div.ll-mobile-see-more{
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding:0.5rem 1.2rem;
  background-color: #822424;
  position: absolute;
  top:-0.5rem;
  right:1rem;
  width:1rem;
  z-index: 1001;
  border-radius: 2rem;
  color: #fafaed;
}

.dashboard-header-container{
  visibility: hidden;
}

.dash-margin-extra{
  margin-top: 5rem;
}


@media only screen and (max-width: 992px) {
  div.sidenav-container-inner{
      display: none !important;
  }
  div.ll-dash-mobile-nav{
      display:flex;
  }
  .ll-dash-mobile-nav-items{
      display: flex;
      flex-direction: row;
  }

  div.Archive-docs-sn-mobi > svg,
  div.scales-just-sn-mobi> svg,
  div.lawyer-sn-mobi> svg,
  div.legalbyte-sn-mobi> svg,
  div.key-sn-mobi>svg,
  div.layers-dash-sn-mobi>svg,
  div.medi-file-sn-mobi>svg{
      width:2.5rem !important;
  }

  .dash-mobilepanel-link-icon-parent{
      padding-top:0.3rem;
  }
  .dash-mobilepanel-link-text{
      font-size: 0.8rem;
      padding:0.5rem;
      margin:0;
  }

  .dashboard-scrollable-container{
      padding-bottom: 5rem;
  }
  .dash-search{
      display: none;
  }

  /* Mobile dash Search hero */
  .dashboard-header-container{
      visibility:visible !important;
  }

  .dashboard-header-container{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 5rem;
      padding-top: 6rem;
  }
  div.dash-search-hero{
      background-color: #fafaed;
      border-radius: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      /* height:15vh; */
      padding:2rem;
  }

  div.ll-dash-mobile-search{
      padding:0;
  }
  .ll-dash-mobile-search>form{
      display: flex;
      flex-direction: row;
      align-items: center;
      width:100%;
      border:#822424 solid 1px;
      border-radius: 2rem;
      padding:0rem 1rem;
  }
  .ll-dash-mobile-search>form>input{
      border-radius: 2rem;
      padding:0.8rem 0.5rem;
      border:none;
      background-color: #fafaed00;
  }

  .ll-dash-mobile-search .ll-search-submitbutton{
      padding-left: 1rem;
  }

    /* Mobile search */

    .ll-dash-mobile-search{
      z-index: 100;
    }

    .ll-dash-mobile-search .form-control:focus{
      background-color:#fafaed !important;
      border: none !important;
      box-shadow: none;
    }


  /* Mobile Prod search and filter */
  div.ll-search-filter-parents{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
  }

  /* LegalProduct Intro Mobile */
  div.prod-mobile-margin{
      margin-bottom:20vh;
      padding-bottom: 10rem;
  }



}

div.product-intro-column{
  margin-bottom: 3rem;
  background-color: #fafaed;
}

/* Cursor inclusions */

.sidenav-logo-container,
.side-dashboard-user-welcome,
.dash-sidepanel-link{
  cursor:pointer;
}

div.ll-product-intro-wrapper{
  background: #fafaed;
  width: 100%;
}


/* FindALawyer */

div.qna-fal>svg,
div.person-search-fal>svg,
div.handshake-fal>svg{
  width:5rem !important;
}


.ll-fal-step-1{
  width:100%;
  /* height:7rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align:center;
  border-radius: 2rem;
}
.ll-fal-step-1>.ll-fal-step-content{
  font-size: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:2rem;
  transition:color 0.2s;
  display: flex;
  color: #383838;
}

.ll-fal-step-1>.ll-fal-step-content-hover{
  font-size:0;
  color:#fafaed;
  transition:color 0.2s;
}
.ll-fal-step-1:hover>.ll-fal-step-content{
  font-size:0;
  width:0;
  padding:0;
  margin:0;
  color:#fafaed;
  transition:color 0.2s;
}

.ll-fal-step-1:hover>.ll-fal-step-content-hover{
  font-size: 1rem;
  display: flex;
  text-align: center;
  padding:2rem;
  transition:color 0.4s;
  color:#383838;
}
div.ll-fal-scales>svg{
  width: 10rem !important;
}

div.form-step{
  border:none;
  background:none;
}

div.form-step form{
  margin: 2rem 0rem;
}

/* div.form-step select,
div.form-step input,
div.form-step .multiselect-container{
  padding:1rem;
  color: #822424;
  border-radius: 2rem;
  border: #822424 2px solid;
  background-color: #fafaed;
} */

div.multiselect-container .search-wrapper{
  border:none;
}

/* Legal Product Tags */
.lcn-tag-orange{
background-color: #f19601;
border: 3px solid #f19601;
border-radius: 30px;
color: #832626;
font-family: 'Work Sans', sans-serif;
white-space: nowrap;
}


/* Legal Product Filters */
button#dropdown.lcn-button-brown{
  background-color:#822424;
border: 3px solid #822424;
border-radius: 30px;
color:#fafaed;
font-family: 'Work Sans', sans-serif;
white-space: nowrap;
padding:1rem;
}

div.ll-prod-search > input,
div.ll-prod-search > span{
  border:#822424 solid 2px;
  padding:1rem;
  border-radius: 2rem;
}

div.ll-prod-search > span{
  background-color: #822424;
  color: #fafaed;
}

/* Front End Nav Active styling modification */
.home-nav-link-middle a.dropdown-item.active{
  background-color: white !important;
  color: #383838;
}

/* ProductIntro */
div.ll-prod-back>svg{
  height:1.2rem !important;

}

.ll-back-button-orange{
background-color: #f19601;
border: 3px solid #f19601;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
border-radius: 30px;
color: #832626;
font-family: 'Work Sans', sans-serif;

}

.ll-back-button-orange:hover {
  background-color: #fcb900;
  transition: 0.3s;
}

.ll-prod-button-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ForIncubators */


/* For Incubators front end Form */
div.incubator-form h4{
  color:#fafaed;
}

/* Header Nav */

div.ll-nav-modal-supgear>svg,
div.ll-nav-modal-teamwork>svg,
div.ll-nav-modal-hammer>svg{
  width:2rem !important;
}

.ll-drop-nav-header-text{
  font-size: 0.8rem;
  margin-left:5px;
  font-weight: 500;
}
div.nav-heading-parent{
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin:0.8rem 1rem;
  border-bottom:#822424 2px solid ;
  padding-bottom: 5px;
}

/* TermsOfServiceScreen */
.tos-container{
  margin-top:12vh;
}