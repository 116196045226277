/* ForLawyerScreen*/

.join-our-network-form-container{
    justify-content: center;
 
}



/* Quick Link section */

.ll-metric-card-container.ll-quick-link-card.col{
    cursor: pointer;
  }


.login-form-container-header-comp{
    align-items: center;
}

.register-header-container-comp{
    align-items: center;
}

/* Health Check Form */
.documents--sort-button{
    
    /* background:"https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/sort.png"; */
    background-color: #fafaed;
    height: 20px;
    width: 20px;
    border:none;
}


/* Product Card Info Hoverover */

.ll-icon-light{
    cursor: pointer;
}

.ll-icon{
    cursor:pointer
}

.forgot-password--header{
    display: flex;
}

.ll-mini-card.clickable{
    cursor: pointer;
}


/* Footer CSS's */

.footerlink-policies{
    text-decoration: none;
    color:inherit;
    cursor: pointer;
}

.footer-link{
    cursor: pointer;
}



/* For Lawyers Screen */

.for-lawyer-checkbox{
    display: flex;
    flex-direction: column;
   
}

.lcn-button-orange.health-form-button-next{
    float: right;
    width: 6rem;
    margin-top: 2rem;
}
.lcn-button-orange.health-form-button-back{
    float: left;
    width: 6rem;
    margin-top: 2rem;
}

/* .lcn-button-orange.lawyer-signup{
    height: 3.6em;
    width: 12em;
    margin-left: auto;
    margin-right: auto;

} */

.for-lawyers-checkbox-label{
    padding-top: 0.5em;
    padding-left: 1em;
    display: flex;
}

.form-check-label{
    padding: 0.6em;
    display: flex;
    margin-left: 10px
}


/* Find a Lawyer Updated Screen */


.find-a-lawyer-circle-tab{
    background-color: #832626;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    display: flex;
    max-width: 65px;
    flex-direction: column;
    cursor: pointer;
    
}
.find-a-lawyer-tab-logo{
    height: 65px;
    width: 65px;
}

.find-a-lawyer-steps-tab{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.find-a-lawyer-form-question{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 5px;

}
.find-a-lawyer-question-text{
    margin-right: 8px
}



/* Registration Screens */

.user-register-location-tooltip{
    display: flex;
    flex-direction: row;
    padding: 10px
}

@media only screen and (max-width: 620px){
    div.user-register-location-tooltip{
        display: flex;
        flex-direction: column;
    }
}

.rulebook-ty{
    width:10rem;
}
.test.register-smeCity{
        
        padding:1rem;
        color: #822424;
        border-radius: 2rem;
        border: #822424 2px solid;
        background-color: #fafaed;
        box-shadow:  -11px -11px 37px #dfdfd3,
        11px 11px 37px #ffffff;
        height: 60px;
        margin-left: 20px;
      
}
@media only screen and (max-width: 620px){
    div.register-screen-age-size{
        display: flex;
        flex-direction: column
}
}

/* Legal checks Screen */

.find-lawyer-hero.legal-checks{
    justify-content: center;
} 

div.checks-cards-parent{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    max-width: 23rem;
}

.lcn-button-orange.back{
    width: 6rem;
}

.popi-action{
    border-radius: 2rem;
  border: #f19601 2px solid;
  background-color: #fafaed;
  box-shadow:  -11px -11px 37px #dfdfd3,
  11px 11px 37px #ffffff;
  padding: 1em;
  margin-bottom: 1em;
}

#legal-checks-parent{
    width: 80%;
    margin-left: auto;
    margin-right: auto; 
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 1800px) {
    .checks-cards-parent{
       
             
            padding-top: 1rem;
            
            
    }}

@media (max-width: 1020px) {

.ll-card-interact.ll-ty-legalproducts.top{
   
         
        margin-bottom: 1rem;
        
        
}}


/* Incubator & Eco Styling */

.accordion-body{
    background-color: #fafaed;
   /*  border:1px solid #832626; */
    
}

#accordion-item{
    border: none
}

.loader {
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
  }

.bell-icon{
    color:#832626
}

.bell-icon:active{
    color:#f19601
}

/* Doc Review Stuffs */

#doc-review-inputs{
    color: #822424;
    border-radius: 2rem;
    border: #822424 2px solid;
    background-color: #fafaed;
    box-shadow:  -11px -11px 37px #dfdfd3,
    11px 11px 37px #ffffff;
}

.review-modal-header{
    text-align: center;
}


#investment-score{
    text-align:left;
}

.form-switch.form-check-input{
    width: 4em;
}

div.form-switch .form-check-input{
    width: 4em;
}

div.form-switch .form-check-input:checked{
    background-color: #822424;
    border-color: #822424;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

div.form-switch .form-check-input:focus {
    border-color: #822424;
}
div.form-switch .form-check-input:not(:checked):focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}


.due-diligence-header{
display: flex;
flex-direction: column;
}


.disbled-button{
    border-radius: 30px;

}

#tooltip-gpt-review{
    z-index: 100;
}

@media screen and (max-width: 1000px) {
    div.doc-card-headers {
      display: none;
    }
  }


  .lawyer-suggestions{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
  }

  .lawyer-suggestion{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .suggestion-button{
    border-radius: 16px;
    padding: 8px;
    box-shadow: #822424;
    text-shadow: #822424;
  }


/* CSS */
.suggestion-button {
  align-items: center;
  background-color: #f19601;
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #822424;
  display: flex;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 19px 24px;
  white-space: nowrap;
  cursor: pointer;
}

.clear-link{
    text-decoration: none;
}



.tour-bicycle{
    cursor: pointer;
}

.sme-posthealth-upgrade-col{
    cursor: pointer;
}

.subscribe-button-container{
    display: flex;
    justify-content: center;
    margin-top: 10px
}

.review-modal-text{
    border-radius: 8px;
    
    padding: 5px;
    margin-bottom: 6px;
    
    margin-top: 6px;
}


/* div.entire-modal>.modal-dialog{
max-width: 50%!important;
}  */

.doc-review-modal{
    background-color: #fafaed;
}

.review-modal-title{
    justify-content: center;
}

.review-modal-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.BBBEE-question{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.bbbee-back-button{
  background-color: #f19601;
  border: 3px solid #f19601;
  border-radius: 30px;
  color: #832626;
  font-family: 'Work Sans', sans-serif;
  white-space: nowrap;
  cursor: pointer;
  width: 100px;
}


.bbbee-hero{
    padding-top: 64px;
    display: flex;
    flex-direction: column-reverse;

}

.bbbee-result{
    padding:1rem;
        color: #822424;
        border-radius: 2rem;
        border: #822424 2px solid;
        background-color: #fafaed;
        box-shadow:  -11px -11px 37px #dfdfd3,
        11px 11px 37px #ffffff;
        margin-left: 20px;
}

.bbbee-links{
    cursor: pointer;
    color: #822424;
}