/* Css Container support code for when we are able to use */
/* div.short-scroll{
    display: none;
  }

  .sidenav-container-inner{
    container-type:size;
    container-name:sidenav;
  }

  @container sidenav (min-height:740px){
    div.short-scroll{
        display:flex;
    }
  } */

.login-form-container-embed {
  z-index: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
}

div.bulb-on-card-lot > svg {
  width: 2rem !important;
}

div.card-toggle-parent {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
}

div.bulb-on-card-lot {
  position: absolute;
  top: 0rem;
  right: 0.1rem;
  padding: 0.2rem;
  background-color: white;
  border-radius: 2rem;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
}

.ll-icon {
  color: #832626;
  font-size: 1.5rem;
}
.ll-icon-light {
  color: #fafaed;
  font-size: 1.5rem;
}

div.legal-health-check-lot-sn > svg {
  width: 2.5rem !important;
}

div.brain-sn > svg,
div.new-matter-ql > svg {
  width: 3rem !important;
}

@media only screen and (max-width: 1000px) {
  .ll-dash-top-content,
  .ll-dash-bottom-content {
    margin: 0px !important;
  }
}

/* Health Check forms*/
div.active-tab {
  background-color: #832626;
  color: #fafaed;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
}

div.tab {
  border: #832626 solid 2px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
}

.health-check-form-step {
  /* height: 20rem; */
  /* width: 4rem; */
  padding: 4rem 2rem 2rem 2rem;
  margin: 2rem;
}

.health-check-form {
  border-radius: 2rem;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}

select.form-select,
div.form-step input,
div.form-step .multiselect-container {
  padding: 1rem;
  color: #822424;
  border-radius: 2rem;
  border: #822424 2px solid;
  background-color: #fafaed;
}

/* Mobile Home button */

div.ll-mobile-go-home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background-color: #822424;
  position: absolute;
  top: -0.4rem;
  left: -0.4rem;
  width: 1rem;
  z-index: 1001;
  border-radius: 2rem;
  color: #fafaed;
  font-size: 0.8rem;
}

@media only screen and (max-width: 643px) {
  .ll-mobile-see-more {
    display: flex !important;
  }
}

.dash-search-hero {
  position: relative;
}

div.ll-mobile-sign-out {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  /* background-color: #822424; */
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  z-index: 1001;
  border-radius: 2rem;
  color: #822424;
  font-size: 0.8rem;
}

div.ll-mobile-profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  /* background-color: #822424; */
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 1rem;
  z-index: 1001;
  border-radius: 2rem;
  color: #822424;
  font-size: 0.8rem;
}

div.feedback-lottie-dash > svg {
  background-color: #fafaed;
  border-radius: 2rem;
  width: 2.5rem !important;
}

div.feedback-lottie-dash {
  position: absolute;
  top: 7%;
  right: 5%;
  width: 2.6rem;
}

/* Home page metrics */

.homepage-credential {
  font-family: "DM Serif Display", serif;
}

.lacoona-home-metric {
  font-size: 4rem;
}

/* Card Pill */

.side-dashboard-user-welcome {
  padding: 0.8rem 1.5rem;
  border-radius: 2rem;
  /* height:6rem; */
}

/* Cookie Consent */

.ll-cookie-consent {
  position: fixed;
  min-width: 8rem;
  max-width: 20rem;
  margin-bottom: 1rem;
  bottom: 2rem;
  left: 1rem;
  padding: 1rem 1.2rem;
  background-color: #822424;
  border-radius: 2rem;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}

.ll-cookie-consent-content {
  color: #fafaed;
}

.ll-cookie-button {
  background-color: #f19601;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  border: none;
  margin-top: 0.8rem;
}

.ll-cookie-consent-content a {
  color: #fafaed;
}

@media only screen and (max-width: 576px) {
  div.ll-feedback {
    display: none;
  }
  .ll-cookie-consent {
    margin-bottom: 10rem;
  }
  .ll-search-results {
    width: 100% !important;
    --bs-gutter-x: 0 !important;
    padding: 0 !important;
  }
}

/* Search results additional styles */
@media only screen and (max-width: 992px) {
  .ll-frontend-search-mobile {
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 300px) {
  .lcn-button-orange {
    white-space: normal !important;
  }
}

.ll-search-results-item-container {
  justify-content: center;
}

@media only screen and (max-width: 992px) {
  .ll-feedback {
    display: none;
  }
  .health-buttons {
    width: 80%;
  }
}

/* searchbar */

.ll-frontend-search {
  display: none;
}

.ll-frontend-search-mobile {
  display: none;
  flex-direction: row;
  justify-content: center;

  /* margin-top: 5rem; */
}
.ll-frontend-search-mobile > form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

#homepage-divider {
  margin-top: 5vh !important;
}

@media only screen and (max-width: 1260px) {
  #homepage-divider {
    margin-top: 5vh !important;
  }
  div.ll-frontend-nav {
    align-items: center;
    justify-content: center;
  }

  .ll-frontend-search-mobile {
    margin-top: 2rem;
  }
}

/* Custom scroll bars */

.ll-custom-scrollbar {
  scrollbar-gutter: stable;
  overflow-y: auto;
}

.ll-custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.section::-webkit-scrollbar-thumb:hover {
  background-color: #f19601;
}
.ll-custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 0 2rem 2rem 0;
  overflow: none;
}

.ll-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #822424;
  border-radius: 2rem;
}

/* Product Info Tags */

.ll-neomorphic-inset {
  background: #fafaed;
  padding: 0.8rem;
  /* height:4rem; */
  border-radius: 1rem;
  box-shadow: inset 0.5rem 0.5rem 0.6rem #dcdcd1,
    inset -0.5rem -0.5rem 0.6rem #ffffff;
}

.bot-category {
  background-color: #f19601;
  margin: 6px;
  box-shadow: 6px 6px 15px #d5d5c9, -6px -6px 15px #ffffff;
}

/* Accordion styling */

button.accordion-button {
  border-radius: 2rem !important;
  border: #822424 solid 1px;
}

button.accordion-button:focus {
  border-radius: 2rem !important;
  border: #f19601 solid 1px;
  /*   box-shadow:  20px 20px 60px #d5d5c9,
             -20px -20px 60px #ffffff; */
}

button.accordion-button.collapsed {
  background-color: #fafaed;
  border-radius: 2rem;
  /*   box-shadow:  -11px -11px 37px #dfdfd3,
             11px 11px 37px #ffffff; */
}

button.accordion-button:not(.collapsed) {
  background-color: #fafaed;
  color: #343434;
  border-radius: 2rem;
}

.accordion-header {
  border: none;
  border-color: #fafaed;
  background-color: #fafaed;
}

div.ll-accordion-parent.accordion-item {
  border-radius: 2rem;
  background-color: #fafaed;
}

input#smeName,
input#address,
input#websiteURL,
input#smeLocationNew,
input#operationTimeNew,
input#smeSizeNew,
input#contactNumber,
input#linkedInProfile,
input#smeDescription,
input#smeVision,
input#smeSize,
input#operationTime,
input#smeLocation,
input#smeCity,
div.form-step select,
div.form-step input,
div.form-step .multiselect-container {
  padding: 1rem;
  color: #822424;
  border-radius: 2rem;
  border: #822424 2px solid;
  background-color: #fafaed;
  /* box-shadow:  -11px -11px 37px #dfdfd3,
  11px 11px 37px #ffffff; */
}

.ll-metric-card-container.ll-quick-link-card.col {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-content: stretch;
}

.ll-metric-card.p-2.ll-card-interact.row {
  width: 100%;
}

div.ll-home-icons-hammer > svg,
div.ll-home-icons-internet > svg,
div.ll-home-icons-rule-book > svg,
div.ll-home-icons-suitcase > svg,
div.ll-home-icons-handshake > svg,
div.ll-home-icons-bulb > svg,
div.ll-home-icons-employee > svg,
div.ll-home-icons-sup-gear > svg {
  width: 5rem !important;
}

.homepage-card.card {
  border-radius: 1rem;
  height: 100%;
}

/* HealthCheckForm */

.health-buttons {
  width: 40%;
}

.ll-health-check-submit-text {
  font-weight: 400;
  width: 60%;
  vertical-align: middle;
}

.health-check-save-text {
  border-radius: 2rem;
}

.ll-health-button-container {
  margin: auto;
  align-self: flex-end;
}

/* Find a lawyer styles */
.highlight-title-small {
  color: #f19601;
}

.fal-focus-number {
  font-size: 5rem;
  color: #f19601;
  font-weight: 600;
  margin: auto;
}

div.fal-action-steps-last {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* For Lawyers Update */

.ll-content-image {
  max-width: 100%;
}

div.focus-num-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.for-lawyers-cards-front {
  height: 11rem;
}

.for-lawyer-card-title {
  font-family: "DM Serif Display", serif;
}

div.for-lawyers-cards-front-inner {
  height: 100%;
}

div.fl-card-title-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 992px) {
  div.row-reverse-medium {
    display: flex;
    flex-direction: column-reverse;
  }
}
div.mobile-version {
  display: none !important;
}
@media only screen and (max-width: 992px) {
  div.full-version {
    display: none !important;
  }
  div.mobile-version {
    display: flex !important;
  }
}

div.faf-button-parent {
  width: 100% !important;
}

@media only screen and (max-width: 620px) {
  button.mobile-wrap {
    white-space: pre-wrap !important;
  }
  div.hero-button-parent.row {
    width: 100%;
  }
  div.lcn-section-spacing {
    padding: 2.5rem 1rem;
  }
}

/* For Lawyers modal form */

div.form-check {
  display: flex;
  flex-direction: row;
  align-items: center;
}

label.form-check-label {
  margin-left: 1rem;
}

/* FindALawyer Dashboard page form styles */

@media only screen and (max-width: 992px) {
  div.find-a-lawyer-form-question {
    flex-direction: column;
  }
  div.find-a-lawyer-form-question p {
    width: 100%;
  }
}
div.faq-card-parents {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

@media only screen and (max-width: 1000px) {
  #basic-nav-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

div.ai-question-text {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

div.ll-brain > svg {
  width: 5em !important;
}

div.ll-brain-mobile-icon > svg,
div.ll-bulb-mobile-icon > svg {
  width: 2.5rem !important;
}

@media only screen and (max-width: 993px) {
  .LandbotLivechat {
    display: none;
  }
}

/* Product Intro Update with validator */
div.product-intro-column {
  justify-content: flex-start;
}

.continue-registration-card {
  background-color: #822424;
  border-radius: 20px;
  color: white;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
}

.notification-card {
  border-radius: 20px;
  border: #822424 3px solid;
  color: #822424;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
  padding: 2rem 4rem;
  margin: 1rem;
}

/* Shepard Tour */
.shepard-step {
  background-color: #fafaed;
  border-radius: 20px;
  box-shadow: 0px 30px 39px 25px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 30px 39px 25px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 30px 39px 25px rgba(0, 0, 0, 0.3);
  padding: 1rem 1rem 1rem 1rem;
  z-index: 1000000;
  backdrop-filter: blur(15px) saturate(180%);
  -webkit-backdrop-filter: blur(15px) saturate(180%);
  background-color: rgba(250, 250, 237, 0.5);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);

  -webkit-animation-name: shepard-step;
  animation-name: shepard-step;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.shepard-step > button {
  cursor: pointer;
}
@-webkit-keyframes shepard-step {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes shepard-step {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shepherd-content {
  position: relative;
  border: #822424 1px dashed;
  padding: 1rem;
  border-radius: 20px;
}
.shepherd-cancel-icon {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  color: #822424;
  background-color: #fafaed;
}

.shepard-button-secondary,
.shepherd-button-primary {
  margin: 1rem;
  padding: 0 2rem;
}

.shepherd-arrow,
.shepherd-arrow::before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;
}
.shepherd-arrow:before {
  content: "";
  transform: rotate(45deg);
  background: #fafaed;
}
.shepherd-element[data-popper-placement^="top"] > .shepherd-arrow {
  bottom: -8px;
}
.shepherd-element[data-popper-placement^="bottom"] > .shepherd-arrow {
  top: -8px;
}
.shepherd-element[data-popper-placement^="left"] > .shepherd-arrow {
  right: -8px;
}
.shepherd-element[data-popper-placement^="right"] > .shepherd-arrow {
  left: -8px;
}
.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}

.tour-button {
  padding: 0.5rem;
  border: #822424 2px solid;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  transition: all 0.4s;
  width: 9rem;
}
.tour-button:hover {
  scale: 1.1;
  border: #f19601 2px solid;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
  transition: all 0.4s;
}
.tour-button:active {
  box-shadow: 2px -6px 6px 0px rgba(0, 0, 0, 0.5) inset;
  -webkit-box-shadow: 2px -6px 6px 0px rgba(0, 0, 0, 0.5) inset;
  -moz-box-shadow: 2px -6px 6px 0px rgba(0, 0, 0, 0.5) inset;
  transition: all 0.4s;
}

div.cycle-tour-lottie > svg {
  width: 2.5rem !important;
}

.tour-desktop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.tour-mobile {
  display: none;
}

@media only screen and (max-width: 992px) {
  .tour-desktop {
    display: none;
  }

  .tour-mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .shepard-title {
    font-size: 1rem;
  }
  .shepherd-text {
    font-size: 12px;
  }
}

div.ll-dash-mobile-height {
  height: 1.2rem;
}

div.ll-dash-mobile-height-expand {
  height: 1.2rem !important;
}

@media only screen and (max-width: 643px) {
  div.ll-dash-mobile-height-expand {
    height: 6.2rem !important;
  }
}

@media only screen and (max-width: 349px) {
  div.ll-dash-mobile-height-expand {
    height: 11rem !important;
  }
}

@media only screen and (max-width: 280px) {
  div.ll-dash-mobile-height-expand {
    height: 17rem !important;
  }
}

/* Dashboard update styling */
.start-check-button {
  padding: 0.2rem;
  border: #f19601 2px solid;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  transition: all 0.4s;
  width: 9rem;
}

/* div.update-lh>svg{
  width: 1rem !important;
  height: 1rem !important;
} */

div.lottie-icon,
div.key-upgrade > svg {
  width: 1.8rem !important;
  height: 1.8rem !important;
}

div.parent-sub-scores {
  border: rgb(130, 36, 36) 2px dashed;
  /* background-color:#822424; */
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.8rem 0rem 0.8rem;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.sub-score {
  background-color: #fafaed;
}

.drop-down-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
  padding-bottom: 0.8rem;
}

.arrow-down {
  color: #822424;
  font-size: 2rem;
  background-color: #fafaed;
  animation: myAnim 2s ease 0s 5 normal forwards;
  transition: 0.4s;
  cursor: pointer;
}

.arrow-down:hover {
  color: #f19601;
  transition: 0.4s;
}

@keyframes myAnim {
  0% {
    animation-timing-function: ease-out;
    transform: scale(1);
    transform-origin: center center;
  }

  10% {
    animation-timing-function: ease-in;
    transform: scale(0.91);
  }

  17% {
    animation-timing-function: ease-out;
    transform: scale(0.98);
  }

  33% {
    animation-timing-function: ease-in;
    transform: scale(0.87);
  }

  45% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

.vert-align-end {
  display: flex;
  align-items: center;
  justify-content: end;
}

.vert-align-start {
  display: flex;
  align-items: center;
  justify-content: start;
}

#investment-score {
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

#investment-score > .lottie-icon {
  margin-left: 0.5rem;
}

/* Register Component Updates */

.ll-reg-dummy-selected,
.ll-reg-dummy-unselected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
}

.ll-reg-dummy-selected-hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
  border: #822424 solid 2px;
}

.ll-reg-dummy-selected-hover:hover {
  background-color: #f19601;
  transition: 0.3s;
  color: #822424;
}

.ll-reg-brown {
  background-color: #832626;
  border: none;
  border-radius: 30px;
  padding: 14px 24px;
  color: white;
  margin: 0px 8px;
  font-family: "Work Sans", sans-serif;
  justify-content: center;
  align-items: center;
}

.ll-reg-dummy-selected {
  border: #822424 solid 2px;
}
.ll-reg-dummy-unselected {
  border: #343434 solid 2px;
  background-color: #e8e8e8;
  color: #a6a6a6;
}

div.reg-selector-parentP {
  display: flex;
  flex-direction: column;
}

#tooltip-reg-comp-incu,
#tooltip-reg-comp-eco {
  z-index: 100;
}

/* FAQ Styles */

.lac-faq-parent > h3 {
  font-family: "DM Serif Display", serif;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.lac-faq-parent > h2 {
  font-family: "DM Serif Display", serif;
  font-style: italic;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.lac-faq-parent > h5 {
  font-style: italic;
  font-size: 0.8rem;
}

.ll-align-register-login-element-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: flex-end;
}

.ll-align-register-login-element-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: flex-start;
}

@media only screen and (max-width: 1000px) {
  .ll-align-register-login-element-right {
    align-items: center;
    text-align: center;
  }
  .ll-align-register-login-element-left {
    align-items: center;
    text-align: center;
  }

  div.ll-product-intro-wrapper {
    padding: 2rem;
  }
}

@media only screen and (max-width: 500px) {
  .lcn-tag-orange {
    white-space: normal !important;
  }
  .mobile-center-align {
    display: flex;
    justify-content: center !important;
  }
}

/* Enterprise */

div.enteprise-bg {
  background: #fafaed;
}

div.jinx-image {
  width: 100%;
  height: 25rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Jinx.png");
  border-radius: 20px;
}

/* BootstrapClass */

div.ll-ent-section-1 {
  max-width: 50%;
}
div.ll-ent-section-2 {
  max-width: 30%;
}

.ll-content-image {
  max-height: 20rem;
}
@media only screen and (max-width: 1400px) {
  div.ll-ent-section-1 {
    max-width: 70%;
  }
  div.ll-ent-section-2 {
    max-width: 30%;
  }
  div.lcn-page-width-section {
    max-width: 90%;
  }
}

@media only screen and (max-width: 1300px) {
  div.lcn-page-width-section {
    display: flex;
    flex-direction: column;
  }
  div.ll-ent-section-1 {
    max-width: 100%;
  }
  div.ll-ent-section-2 {
    max-width: 100%;
  }
  div.ll-flex-col-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

.ll-burg-card {
  background-color: #822424;
  min-height: 90%;
  color: white;
  border-radius: 20px;
  max-width: 20rem;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin: 1rem;
}

.ll-burg-card > h3,
.ll-burg-card > h4 {
  font-family: "DM Serif Display", serif;
  font-weight: 300;
  line-height: 1.3;
}
.ll-burg-card > p {
  color: white;
}

.ll-lifted-card {
  border-radius: 20px;
  padding: 4rem;
  width: 60%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
  background-color: #fafaed;
}

.ll-lifted-card > h2,
.ll-lifted-card > div > div > h2 {
  font-family: "DM Serif Display", serif;
  font-weight: 300;
  line-height: 1.3;
  color: #822424;
  font-size: 2.5rem;
}

.ll-lifted-card > h5 {
  color: #f19601;
}

.ll-tagline {
  color: #f19601;
}

.ll-card-up {
  margin-top: -3rem;
  margin-bottom: 3rem;
}

.ll-flash-cards {
  border: 1px solid #f19601;
  padding: 1rem;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
  border-radius: 20px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ll-flash-cards > h3 {
  font-family: "DM Serif Display", serif;
  font-weight: 200;
  color: #822424;
}

.ll-burg-short-rule {
  font-size: 1rem;
  height: 0.2rem;
  width: 30% !important;
  background-color: #822424;
  opacity: 100%;
}

.ll-hr-center {
  margin: auto;
}

.ll-accordion-ent {
  margin: 1rem;
}

div.ll-accordion-super-parent {
  width: 60%;
  margin: auto;
}

div.ll-ent-faq {
  margin-top: 5rem;
  padding: 4rem;
}

@media only screen and (max-width: 912px) {
  .ll-lifted-card {
    width: 90%;
  }
  div.ll-ent-faq {
    margin-top: 8rem;
  }
  .ll-lifted-card > h2,
  .ll-lifted-card > div > div > h2 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 480px) {
  div.ll-ent-faq {
    margin-top: 12rem;
  }
  div.ll-accordion-super-parent,
  div.lf-accordion-super-parent {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 300px) {
  .ll-lifted-card > h2,
  .ll-lifted-card > div > div > h2 {
    font-size: 1.2rem;
  }
}

.ent-subtitle {
  font-size: 2rem !important;
}

.ll-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ll-underlinedlink {
  color: #343434;
  font-family: "DM Serif Display", serif;
  text-decoration: solid underline #822424 3px;
  line-height: 1.5;
  text-underline-offset: 6px;
  transition: 0.4s;
}

.ll-underlinedlink:hover {
  color: #822424;
  transition: 0.4s;
}

nav.ll-org-clear-nav {
  background: transparent !important;
}

/* Video Component */

div.org-video-parent {
  width: 40%;
}

.align-vid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Play Button */

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaed;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  animation: pulse 1.5s infinite;
  border: #f19601 solid 5px;
}

.play-button > i {
  width: 100%;
  font-size: 5rem;
  color: #822424;
  height: auto;
}

.video-container {
  position: relative;
  cursor: pointer;
}

.video-thumbnail {
  width: 100%;
  height: auto;
}

.video-container.video-active::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(2px);
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-wrapper {
  position: relative;
  width: 80%;
  height: 80%;
  max-width: 80%;
  max-height: 80%;
}

.video-iframe {
  width: 100%;
  height: 100%;
  border: #f19601 solid 5px;
}

.close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 1001;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 2rem;
  color: #822424;
  animation: pulse 1.5s infinite;
}

.ll-align {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

@media only screen and (max-width: 512px) {
  .ll-align {
    align-items: center;
    justify-content: center;
  }
}

/* Calendar */

div.lacoona-class button {
  background-color: #822424;
  border-radius: 20px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
div.rbc-toolbar button {
  background-color: #822424;
  color: white;
  border-radius: 20px !important;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

div.rbc-toolbar button:hover {
  background-color: #f19601;
  color: #822424;
}
div.rbc-toolbar button:focus {
  background-color: #f19601;
  color: #822424;
}
div.rbc-toolbar button:active {
  background-color: #f19601 !important;
  color: #822424;
}

/* div.rbc-toolbar button:active{
  background-color:#f19601 !important;
  color:#822424 ;
} */

button.rbc-active {
  background-color: #f19601 !important;
  border: #822424 1px solid;
}

div.rbc-month-view {
  border-radius: 20px;
  border: #822424 1px solid;
}

span.rbc-toolbar-label {
  font-family: "DM Serif Display", serif;
}

div.rbc-month-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

div.rbc-month-header span {
  font-family: "DM Serif Display", serif;
}
div.rbc-event {
  background-color: #822424;
}

/* Lawyers Side Pan */

.lawyers-avatar {
  max-height: 4rem;
}

.left-border {
  border-left: #832626 solid 2px;
  margin-left: 1rem;
}

.right-border {
  border-right: #832626 solid 2px;
  margin-right: 1rem;
}

/* FAL User Stylings */

.lawyer-list-parent {
}

div.lawyer-card {
  max-width: 16.5rem;
  border-radius: 20px;
  border: #822424 1px solid;
  margin: 1rem;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.4s;
}

div.lawyer-card:hover {
  border: none;
  border-radius: 20px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
  transition: 0.4s;
}

/* Lawyer finestrokes */

div.lawyer-scales-lottie > svg {
  width: 12rem !important;
}

div.scales-sn-mobi > svg {
  width: 2.5rem !important;
}

/* Fal Fine strokes */

.rbc-toolbar .rbc-toolbar-label {
  margin-top: 1rem;
}

.lcn-button-orange.lawyer-signup {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.lcn-checkout-back-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lcn-meet-details-parent {
  width: 80%;
}

.ll-lawyer-name-fal {
  overflow-wrap: break-word;
}

div.entire-modal > .modal-dialog {
  /* max-width: 50%!important; */
}

.doc-review-modal.modal-body {
  border-radius: 20px;
}

/* Matter Job Board */
.matter-bid-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.matter-title-parent {
  display: flex;
  flex-direction: column;
}

.matter-title-parent > hr {
  margin-top: 1rem;
}

.matter-status-container,
.matter-status-container > a {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}

.matter-status-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.matter-status-open {
  color: greenyellow;
}

.matter-status-closed {
  color: crimson;
}

.matter-status-quoting {
  color: darkorange;
}

.quoted-matter-page {
  background-color: #fafaed;
  padding: 3rem;
  height: 100vh;
}

.lcn-btn-disabled {
  background-color: #808080 !important;
  color: white !important;
  cursor: not-allowed;
}

p.\!ms-mt-2.\!ms-mb-0.\!ms-p-0.ms-text-xs.ms-text-\[10px\].sm\:ms-text-xs {
  display: none !important;
}

.hide-mendable {
  display: none !important;
}

button.ms-z-10.ms-m-0.ms-flex.ms-items-center.ms-whitespace-nowrap.ms-gap-1.ms-rounded-lg.ms-p-0.ms-text-sm.hover\:ms-cursor-pointer.sm\:ms-text-base.ms-py-\[1px\].ms-px-\[7px\].ms-transition-all.disabled\:ms-bg-opacity-10 {
  background-color: #822424 !important;
}

/* Weita Page Styles */
.weita-bg {
  background: #fff;
}

.lf-hero-font,
.lf-body-font {
  font-family: "Nunito";
}
.lf-hero-font {
  font-weight: "600";
}

.lf-burg-short-rule {
  font-size: 1rem;
  height: 0.2rem;
  width: 30% !important;
  background-color: #003963;
  opacity: 100%;
}

/* Accordion styling */
.lf-accordion-parent button.accordion-button {
  border-radius: 2rem !important;
  border: #003963 solid 1px;
}

.lf-accordion-parent button.accordion-button:focus {
  border-radius: 2rem !important;
  border: #003963 solid 1px;
  /*   box-shadow:  20px 20px 60px #d5d5c9,
             -20px -20px 60px #ffffff; */
}

.lf-accordion-parent button.accordion-button.collapsed {
  background-color: #e4ebf3;
  border-radius: 2rem;
  /*   box-shadow:  -11px -11px 37px #dfdfd3,
             11px 11px 37px #ffffff; */
}

.lf-accordion-parent button.accordion-button:not(.collapsed) {
  background-color: #e4ebf3;
  color: #343434;
  border-radius: 2rem;
}

.lf-accordion-parent .accordion-header {
  border: none;
  border-color: #fafaed;
  background-color: #e4ebf3;
}

div.lf-accordion-parent.accordion-item {
  border-radius: 2rem;
  background-color: #e4ebf3;
}

.lf-accordion-parent .accordion-body {
  background-color: #e4ebf3;
}

div.lf-accordion-super-parent {
  width: 60%;
  margin: auto;
}

.lf-mendable
  button.ms-z-10.ms-m-0.ms-flex.ms-items-center.ms-whitespace-nowrap.ms-gap-1.ms-rounded-lg.ms-p-0.ms-text-sm.hover\:ms-cursor-pointer.sm\:ms-text-base.ms-py-\[1px\].ms-px-\[7px\].ms-transition-all.disabled\:ms-bg-opacity-10 {
  background-color: #003963 !important;
}

.lf-blue {
  color: #003963;
}

.lf-button {
  background-color: #e4ebf3;
  border: 3px solid #003963;
  border-radius: 30px;
  color: #003963;
  font-family: "Nunito";
  white-space: nowrap;
  cursor: pointer;
}

.lf-blue-background {
  background-color: #e4ebf3;
}

@media only screen and (max-width: 480px) {
  div.lf-accordion-super-parent {
    width: 90%;
    margin: auto;
  }
}

.lf-dots-bg {
  position: relative;
  background-image: url("https://legalfundi.com/wp-content/uploads/2023/09/Group-451-1.svg");
  background-repeat: repeat-x; /* Prevents the default repeat behavior */
  background-position: center center; /* Centers the background vertically and horizontally */
  background-size: auto 40%; /* Sets the image size to 30% of the height */
}

/* .hero-body-bg {
  background-color: white;
  border-radius: 2rem;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%);
  padding: 2rem 5rem !important;
} */
