.screen-container {
 margin-top: 120px;
}

.cart-description-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f19601;
    border-radius: 16px;

}

.select-form-container {
    margin-top: 300px;
}

.homepage-card-container {
    display: flex;
    justify-content: center;
    max-width: 70%;
}

/* homescreen */

.home-nav-link-middle {
    font-weight: 500;
    margin-left: 1rem;
    margin-right: 1rem;

}

/* #homepage-divider {
    margin-top: 15vh !important;
} */

/* health check form */
/* 
.health-check-form-step {
    height: 20rem;
    width: 4rem;
    border: 1px solid black;
} */

/* .tab {
    cursor: pointer;
}
.active-tab {
    cursor: pointer;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
} */

/* login and register screens */

.full-screen-container {

    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    /* padding-right: 0px !important;
    padding-left: 0px !important; */
  
  }

.login-register-container{
    margin-top: 5rem !important;
    min-height: 100vh;
}

.login-register-container-2{
  min-height: 100vh;
}

.login-form-column {
    /* margin-top: 5rem !important; */
    align-items: center;
    z-index: 1;
    position: absolute;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    min-height: 100vh;
}

.login-form-container {
    z-index: 1;
    background-color:white;
    border-radius: 8px;
    box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.75);
    max-width: 40%;
}

/* RegisterThankYouScreen */

.ll-thankyou-nav-card{
    width:100%;
    border-radius: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }

  /* SearchResultsDash */
  .search-result-title-container {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  /* ForgotPasswordScreen */

  .forgot-pass-form {
    display: none;
  }

  /* PasswordResetScreen */

  .success-message {
    color: green;
  }

  /* Precheckoutscreen */

  .pre-checkout-proceed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pre-checkout-feature-container {
    background-color: #fafaed;
    border-radius: 16px;
  }

  .bot-image-pre-checkout {
    max-height:10rem;
  }

  .pre-checkout-bot-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pre-checkout-next-steps {
    background-color: #f19601;
    border-radius: 16px;

  }
  .pre-checkout-buttons {
    display: flex;
    flex-direction: row !important;
    width: 100%;
  }

  .pre-checkout-brown-button-col{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* CheckoutScreen */

  .checkout-image {
    max-width: 60%;
  }

  .divider {
    height: 300px;
  }

  .checkout-loader-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* SMEDash */

  .sme-token-col {
    display: flex;
    flex-direction: column;
    min-height: 100% !important;
    align-items: stretch;
    justify-content: space-between;
}

.sme-journey-col {
    display: flex;
    flex-direction: column;
    min-height: 100% !important;
    align-items: stretch;
    justify-content: space-between;
    border-right: 2px solid #832626;
}

  .sme-token-number-row {
    display: flex;
    align-items: center;
    justify-content: center;
  } 

  .sme-token-number {
    display: flex;
    justify-content: center;
    font-size: 90px;
    font-family: 'DM Serif Display', serif;
  }

  .sme-posthealth-credit-word-col {
    display: flex;
    align-items: center;
  }

  .sme-posthealth-upgrade-col {
    display: flex;
    justify-content: center;
  }

  .ll-metric-percentage-general-hidden {
    opacity: 0;
  }

  /* ProductIntro */
  #prod-intro-start-button{
    width: 60%;
  }
  .product-intro-credit-number {
    border: 2px solid #f19601;;
    font-size: 40px;
    color: #822424;
    background-color: white;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  .use-credit {
    justify-content: center;

  }

  /* CancelSbscriptionScreen */
  .feedback-form {
    height: 200px;
  }
  .feedback-container{
    /* border: 2px solid #f19601; */
    border-radius: 16px;
    /* box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 75%); */
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);

  }

  /* CheckoutCancelScreen */
  .request-cancel-message {
    color:#f19601;
  }

  /* Pricing page */
  .cream-background {
    background-color: #fafaed;
  }
  .pricing-feature-container {
    background-color: #fafaed;
    box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 25%);
  }

  .pricing-feature-container-white {
    background-color: white;
    box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 25%);
    border-radius: 32px;
  }

  div.mediafilelot-pricing > svg {
    height:50px !important;
  }

  div.brainlot-pricing > svg {
    height:50px !important;
  }

  div.documentlot-pricing > svg {
    height:50px !important;
  }

  div.legalbytelot-pricing > svg {
    height:50px !important;
  }

  div.lawyerlot-pricing
  > svg {
    height:50px !important;
  }
  div.hammerofjusticelot-pricing
  > svg {
    height:50px !important;
  }

  .pricing-feature-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tiled-background-pricing {
    height: 200px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    background-color: #fafaed;
    background-image: url("https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-Legal-Back-banner.svg");
    /* background-repeat: repeat-x; */
  }
  .tiled-background-pricing2 {
    height: 200px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    background-color: #fafaed;
    background-image: url("https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-Legal-Back-banner.svg");
    transform: scaleX(-1);

  }

  .premium-subscription-container {
    width: 80%;
    position: relative;
    top: -450px;
    z-index: 1;
    border-radius: 32px;
    margin-bottom: -450px;
  }

  /* fullscreenloader */

  .full-screen-loader-container {
    height: 100vh;
  }

  .full-screen-loader-row {
    min-height: 100%;
  }

  .full-screen-loader-col {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Find a laywyer */

  /* .lawyer-card {
    border: 1px solid #822424;
    border-radius: 8px;
    /* background-color: #822424; 
  } */

  /* Findalawyer */
  .react-time-picker {
    padding: 1rem !important;
    color: #822424 !important;
    border-radius: 2rem !important;
    border: #822424 2px solid !important;
    background-color: #fafaed !important;
  }

  /* LawyerSchedule */


  .repeat-button-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lawyer-schedule-numberdropdown {
    width: 40px !important;
  }

  .repeater-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .repeater-col {
  display: flex;
  align-items: center;
  justify-content: end;
  }

  /* LawyerJobBoard */
  .case-card {
    border: 1px solid black;
  }

@media only screen and (max-width: 1000px) {

    /* homescreen */
    .ll-frontend-nav-container {
        flex-wrap: nowrap;
        padding-right: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        margin-right: -10rem;
    }

    .home-nav-link-middle {
        font-weight: 500;
        margin-left: 0;
        margin-right: 0;
    
    }

    #homepage-generate-legal-docs-button {
        margin-top: 1rem;
    }

    .homepage-section-cream {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .homepage-how-to {
        margin-bottom: 1rem;
    }

    .homepage-image-container {
        margin-bottom: 2rem;
    }

    .homepage-howto-number-wrapper {
        justify-content: center;
    }
    .homepage-howto-text-wrapper {
        justify-content: center;

    }


    /* footer */
    #footer-col-wrapper {
        display: flex;
        flex-direction: column;
    }

    .footer-link {
        justify-content: center;

    }

    .footer-link-header {
        justify-content: center;
        margin-top: 16px !important;
    }

    #footer-logo-wrapper {
        justify-content: center;
    }

    #footer-slogan {
        display: flex;
        justify-content: center;
    }

    /* searchbar

    .ll-frontend-search {
        display: none;
    }
    
    .ll-frontend-search-mobile {
        display: none;
        flex-direction: row;
        justify-content: center;

        margin-top: 5rem; 
    }
    .ll-frontend-search-mobile>form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 5rem;
    } */ 

    /* login and register screens */

    .login-form-container {
        z-index: 1;
        background-color:white;
        border-radius: 8px;
        box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.75);
        max-width: 95%;
    }

    .login-register-container {
        margin-top: 5rem !important;
        min-height: 150vh;
    }
    
    /* SMEDash */
    .start-your-journey-parent {
      flex-direction: column !important;
    }

    .sme-journey-col {
      border-right: none;
      margin-bottom: 16px;
    }

    #divider-under-token {
      display: none;
    }

    /* Documents.js */
    .legal-product-container {
      flex-direction: column;
    }

    .ll-doc-go-parent {
      align-items: center;
    }

    .ll-doc-delete-parent {
      display: flex;
      flex-direction: column;
      margin-top: 10px !important;
      align-items: center;
    }

    .ll-doc-date-parent {
      align-items: center;
    }

    .ll-doc-title-parent {
      align-items: center !important;
    }

    #generated-doc-folder {
      margin-top: 1rem;
    }

    /* Pricing page */
    .subscribe-button-col {
      margin-top: 16px !important;
    }
    .cart-subscribe-parent{
      padding-left: 0px !important;
      padding-right: 0px !important;

    }

    #premium-subscription-container-id {
      padding-left: 2rem !important;
      padding-right: 2rem !important;

    }
}

@media only screen and (max-width: 1260px) {

  

    .ll-frontend-search-mobile {
        display: flex;
    }   
    .ll-frontend-search{
        visibility: hidden;
    }
}